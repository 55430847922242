export const baseColors = {
	neutrals: {
		1000: "#000000",
		980: "#080808",
		950: "#101010",
		920: "#161616",
		900: "#1A1A1A",
		850: "#242424",
		800: "#333333",
		700: "#4B4B4B",
		600: "#646464",
		500: "#7E7E7E",
		400: "#979797",
		300: "#B1B1B1",
		200: "#CBCBCB",
		100: "#E5E5E5",
		70: "#EFEFEF",
		50: "#F2F2F2",
		20: "#FAFAFA",
		0: "#FFFFFF",
	},
	green: {
		900: "#0B2117",
		800: "#0F3826",
		700: "#0B6936",
		600: "#19AA4D",
		500: "#3DE86D",
		400: "#64ED8A",
		300: "#8BF1A7",
		200: "#B1F6C5",
		100: "#D2F9D4",
	},
	yellow: {
		900: "#4F3A0E",
		800: "#745513",
		700: "#B57C02",
		600: "#D0A240",
		500: "#FBC450",
		400: "#FCD073",
		300: "#FDDC96",
		200: "#FEE7BB",
		100: "#FFF2CD",
	},
	blue: {
		1000: "#050E29",
		900: "#051128",
		800: "#0A2250",
		700: "#103278",
		600: "#1543A0",
		500: "#1A54C8",
		400: "#417CF1",
		300: "#76A4FF",
		200: "#A3BBE9",
		100: "#D9E6FF",
	},
	red: {
		900: "#3F0401",
		800: "#53120F",
		700: "#6F2522",
		600: "#95312D",
		500: "#D8645D",
		400: "#FE817B",
		300: "#FFC7C5",
		200: "#FFD9D7",
		100: "#FFF2F2",
	},
	purple: {
		900: "#3A1659",
		800: "#633495",
		700: "#7C3EC3",
		600: "#944DE7",
		500: "#A964F7",
		400: "#C490F9",
		300: "#DCBCFB",
		200: "#E7D3FD",
		100: "#F1EBFF",
	},
	orange: {
		900: "#461A00",
		800: "#823006",
		700: "#A33B04",
		600: "#C54600",
		500: "#E65300",
		400: "#FC823A",
		300: "#FFB48C",
		200: "#FEE2D4",
		100: "#FFF0E9",
	},
	magenta: {
		900: "#891869",
		800: "#A91A90",
		700: "#CA26A5",
		600: "#E142BC",
		500: "#F877D2",
		400: "#FFACE5",
		300: "#FFCEF2",
		200: "#FEDFF3",
		100: "#FEEFF9",
	},
	teal: {
		900: "#002C46",
		800: "#164C6B",
		700: "#2C6B90",
		600: "#438BB5",
		500: "#6FCAFF",
		400: "#8CD5FF",
		300: "#A9DFFF",
		200: "#C5EAFF",
		100: "#EEFBFF",
	},
	alphaLight: {
		5: "#FFFFFF0D",
		100: "#FFFFFF1A",
		200: "#FFFFFF33",
		300: "#FFFFFF4D",
		400: "#FFFFFF66",
		500: "#FFFFFF80",
		600: "#FFFFFF99",
		700: "#FFFFFFB3",
		800: "#FFFFFFCC",
		900: "#FFFFFFE6",
	},
	alphaDark: {
		5: "#0000000D",
		100: "#0000001A",
		200: "#00000033",
		300: "#0000004D",
		400: "#00000066",
		500: "#00000080",
		600: "#00000099",
		700: "#000000B3",
		800: "#000000CC",
		900: "#000000E6",
		208: "#00000033",
	},
}
