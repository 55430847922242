import { DefaultTheme } from "styled-components"
import { baseColors } from "./colors"

export const dark: DefaultTheme = {
	name: "dark-theme",
	colors: {
		bg: {
			feedback: {
				error: baseColors.red[500],
				success: baseColors.green[800],
				warning: baseColors.yellow[600],
				info: baseColors.blue[600],
				disabled: baseColors.neutrals[850],
				disabledEmphasized: baseColors.alphaLight[100],
			},
			feedbackInverse: {
				error: baseColors.red[200],
				success: baseColors.green[100],
				warning: baseColors.yellow[300],
				info: baseColors.blue[300],
				disabled: baseColors.neutrals[50],
				disabledEmphasized: baseColors.alphaDark[100],
			},
			feedbackCommon: {
				error: baseColors.red[200],
				success: baseColors.green[100],
				warning: baseColors.yellow[200],
				info: baseColors.blue[200],
				disabled: baseColors.neutrals[920],
			},
			section: {
				default: baseColors.neutrals[950],
				emphasized: baseColors.neutrals[980],
				subtle: baseColors.neutrals[920],
			},
			sectionInverse: {
				defaultInverse: baseColors.neutrals[0],
				emphasizedInverse: baseColors.neutrals[20],
				subtleInverse: baseColors.neutrals[50],
			},
			button: {
				primary: baseColors.neutrals[0],
				secondary: baseColors.neutrals[980],
				inverse: baseColors.neutrals[1000],
				primaryHover: baseColors.neutrals[50],
				inversePrimaryHover: baseColors.neutrals[850],
				secondaryHover: baseColors.neutrals[900],
				primaryDisabled: baseColors.neutrals[600],
			},
			overlay: {
				hover: baseColors.alphaLight[200],
				pressed: baseColors.alphaDark[200],
				backdrop: baseColors.alphaDark[800],
			},
			card: {
				emphasized: baseColors.neutrals[0],
				default: baseColors.neutrals[900],
			},
			accent: {
				blue: baseColors.blue[600],
				blue1: baseColors.blue[100],
				blueAccent1: baseColors.blue[600],
				blueAccent2: baseColors.blue[200],
				yellowDefault: baseColors.yellow[600],
				yellowPressed: baseColors.yellow[900],
				yellow3: baseColors.yellow[200],
				green1: baseColors.green[800],
				green2: baseColors.green[100],
			},
			shimmer: {
				level1: baseColors.neutrals[900],
				level2: baseColors.neutrals[850],
			},
			shimmerInverse: {
				level1: baseColors.neutrals[70],
				level2: baseColors.neutrals[100],
			},
			component: {
				l1: baseColors.neutrals[900],
				l2: baseColors.neutrals[850],
			},
		},
		text: {
			primary: baseColors.neutrals[0],
			secondary: baseColors.neutrals[200],
			tertiary: baseColors.neutrals[500],
			disabled: baseColors.neutrals[900],
			textLink: baseColors.green[500],
			alpha: baseColors.alphaLight[700],

			textInverse: {
				primary: baseColors.neutrals[1000],
				secondary: baseColors.neutrals[900],
				tertiary: baseColors.neutrals[500],
				alpha: baseColors.alphaDark[700],
			},
			button: {
				onPrimary: baseColors.neutrals[1000],
				onInverse: baseColors.neutrals[0],
				link: baseColors.green[600],
			},
			feedback: {
				error: baseColors.red[800],
				errorSubtle: baseColors.red[400],
				success: baseColors.green[700],
				successSubtle: baseColors.green[500],
				warning: baseColors.yellow[800],
				warningSubtle: baseColors.yellow[500],
				info: baseColors.blue[800],
				infoSubtle: baseColors.blue[400],
			},
			feedbackInverse: {
				error: baseColors.red[800],
				errorSubtle: baseColors.red[500],
				success: baseColors.green[700],
				successSubtle: baseColors.green[700],
				warning: baseColors.yellow[800],
				warningSubtle: baseColors.yellow[700],
				info: baseColors.blue[800],
				infoSubtle: baseColors.blue[700],
			},
			accent: {
				blue: baseColors.blue[200],
				boldGreen: baseColors.green[600],
				purple: baseColors.purple[400],
				teal: baseColors.teal[200],
				yellow: baseColors.yellow[900],
				darkGreen: baseColors.green[900],
				boldRed: baseColors.red[800],
				bannerRed: baseColors.red[300],
			},
		},
		stroke: {
			button: {
				button: baseColors.neutrals[200],
				buttonInverse: baseColors.neutrals[980],
			},
			card: {
				border: baseColors.neutrals[850],
				divider: baseColors.neutrals[850],
				dividerInverse: baseColors.neutrals[50],
			},
			divider: {
				default: baseColors.neutrals[900],
				emphasized: baseColors.neutrals[850],
				inverse: baseColors.neutrals[50],
			},
			feedback: {
				default: baseColors.neutrals[800],
				defaultSubtle: baseColors.neutrals[900],
				error: baseColors.red[500],
				success: baseColors.green[500],
				warning: baseColors.yellow[700],
				info: baseColors.blue[500],
				active: baseColors.neutrals[0],
			},
			feedbackInverse: {
				default: baseColors.neutrals[50],
				error: baseColors.red[300],
				success: baseColors.green[300],
				warning: baseColors.yellow[300],
				info: baseColors.blue[300],
				active: baseColors.neutrals[1000],
			},
		},
		icon: {
			default: baseColors.neutrals[700],
			defaultInverse: baseColors.neutrals[300],
			primary: baseColors.neutrals[0],
			accent: {
				yellow: baseColors.yellow[600],
				green: baseColors.green[600],
				blue: baseColors.blue[600],
				neutral: baseColors.neutrals[200],
			},
		},
		gradient: {
			banner: {
				blue: "linear-gradient(159deg, #152459 7.17%, #101D3E 91.26%)",
				green:
					"linear-gradient(159deg, #00324D 7.16%, #00324D 7.17%, #002337 91.26%)",
				purple: "linear-gradient(150deg, #261D3F 8.79%, #1F162E 78.88%)",
				yellow: "linear-gradient(136deg, #FFC95E 10.06%, #F5BA46 99.63%)",
			},
		},
	},
}

export const light: DefaultTheme = {
	name: "light-theme",
	colors: {
		bg: {
			feedback: {
				error: baseColors.red[200],
				success: baseColors.green[100],
				warning: baseColors.yellow[300],
				info: baseColors.blue[300],
				disabled: baseColors.neutrals[50],
				disabledEmphasized: baseColors.alphaDark[100],
			},
			feedbackInverse: {
				error: baseColors.red[500],
				success: baseColors.green[800],
				warning: baseColors.yellow[600],
				info: baseColors.blue[600],
				disabled: baseColors.alphaLight[100],
				disabledEmphasized: baseColors.alphaLight[100],
			},
			feedbackCommon: {
				error: baseColors.red[200],
				success: baseColors.green[100],
				warning: baseColors.yellow[200],
				info: baseColors.blue[200],
				disabled: baseColors.neutrals[920],
			},
			section: {
				default: baseColors.neutrals[0],
				emphasized: baseColors.neutrals[20],
				subtle: baseColors.neutrals[50],
			},
			sectionInverse: {
				defaultInverse: baseColors.neutrals[950],
				emphasizedInverse: baseColors.neutrals[980],
				subtleInverse: baseColors.neutrals[920],
			},
			button: {
				primary: baseColors.neutrals[1000],
				secondary: baseColors.neutrals[0],
				inverse: baseColors.neutrals[0],
				primaryHover: baseColors.neutrals[850],
				inversePrimaryHover: baseColors.neutrals[50],
				secondaryHover: baseColors.neutrals[50],
				primaryDisabled: baseColors.neutrals[300],
			},
			overlay: {
				hover: baseColors.alphaDark[200],
				pressed: baseColors.alphaLight[200],
				backdrop: baseColors.alphaDark[800],
			},
			card: {
				emphasized: baseColors.blue[1000],
				default: baseColors.neutrals[0],
			},
			accent: {
				blue: baseColors.blue[600],
				blue1: baseColors.blue[100],
				blueAccent1: baseColors.blue[500],
				blueAccent2: baseColors.blue[300],
				yellowDefault: baseColors.yellow[900],
				yellowPressed: baseColors.yellow[600],
				yellow3: baseColors.yellow[200],
				green1: baseColors.green[800],
				green2: baseColors.green[100],
			},
			shimmer: {
				level1: baseColors.neutrals[70],
				level2: baseColors.neutrals[100],
			},
			shimmerInverse: {
				level1: baseColors.neutrals[900],
				level2: baseColors.neutrals[850],
			},
			component: {
				l1: baseColors.neutrals[20],
				l2: baseColors.neutrals[0],
			},
		},
		text: {
			primary: baseColors.neutrals[1000],
			secondary: baseColors.neutrals[900],
			tertiary: baseColors.neutrals[500],
			disabled: baseColors.neutrals[800],
			textLink: baseColors.green[700],
			alpha: baseColors.alphaDark[700],

			textInverse: {
				primary: baseColors.neutrals[0],
				secondary: baseColors.neutrals[100],
				tertiary: baseColors.neutrals[500],
				alpha: baseColors.alphaLight[700],
			},
			button: {
				onPrimary: baseColors.neutrals[0],
				onInverse: baseColors.neutrals[1000],
				link: baseColors.green[600],
			},
			feedback: {
				error: baseColors.red[800],
				errorSubtle: baseColors.red[500],
				success: baseColors.green[700],
				successSubtle: baseColors.green[700],
				warning: baseColors.yellow[800],
				warningSubtle: baseColors.yellow[700],
				info: baseColors.blue[800],
				infoSubtle: baseColors.blue[700],
			},
			feedbackInverse: {
				error: baseColors.red[800],
				errorSubtle: baseColors.red[400],
				success: baseColors.green[700],
				successSubtle: baseColors.green[500],
				warning: baseColors.yellow[800],
				warningSubtle: baseColors.yellow[500],
				info: baseColors.blue[800],
				infoSubtle: baseColors.blue[400],
			},
			accent: {
				blue: baseColors.blue[500],
				boldGreen: baseColors.green[700],
				purple: baseColors.purple[800],
				teal: baseColors.teal[600],
				yellow: baseColors.yellow[800],
				darkGreen: baseColors.green[900],
				boldRed: baseColors.red[900],
				bannerRed: baseColors.red[700],
			},
		},
		stroke: {
			button: {
				button: baseColors.neutrals[980],
				buttonInverse: baseColors.neutrals[200],
			},
			card: {
				border: baseColors.neutrals[50],
				divider: baseColors.neutrals[50],
				dividerInverse: baseColors.neutrals[850],
			},
			divider: {
				default: baseColors.neutrals[50],
				emphasized: baseColors.neutrals[50],
				inverse: baseColors.neutrals[850],
			},
			feedback: {
				default: baseColors.neutrals[200],
				defaultSubtle: baseColors.neutrals[100],
				error: baseColors.red[500],
				success: baseColors.green[600],
				warning: baseColors.yellow[700],
				info: baseColors.blue[500],
				active: baseColors.neutrals[1000],
			},
			feedbackInverse: {
				default: baseColors.neutrals[800],
				error: baseColors.red[300],
				success: baseColors.green[300],
				warning: baseColors.yellow[300],
				info: baseColors.blue[300],
				active: baseColors.neutrals[0],
			},
		},
		icon: {
			default: baseColors.neutrals[300],
			defaultInverse: baseColors.neutrals[700],
			primary: baseColors.neutrals[1000],
			accent: {
				yellow: baseColors.yellow[600],
				green: baseColors.green[600],
				blue: baseColors.blue[600],
				neutral: baseColors.neutrals[600],
			},
		},
		gradient: {
			banner: {
				blue: "linear-gradient(147deg, #D8E5FF 12.88%, #C7DAFF 87.28%)",
				green: "linear-gradient(180deg, #DDF6FF 0%, #CCF1FF 100%)",
				purple: "linear-gradient(155deg, #E4D6FF 8.08%, #D5C6FF 76.64%)",
				yellow: "linear-gradient(179deg, #FFE68C 2.59%, #FFDA7A 99.15%)",
			},
		},
	},
}

// Light Theme:

// color/STROKE/Feedback/Inverse - Default	Base	Neutrals/Neutral-800 :added
// color/STROKE/Feedback/Error	Base	Red/Red-500 : added
// color/STROKE/Feedback/Inverse - Error	Base	Red/Red-300 : added
// color/STROKE/Feedback/Success	Base	Green/Green-600 : added
// color/STROKE/Feedback/Inverse - Success	Base	Green/Green-300 : added
// color/STROKE/Feedback/Warning	Base	Yellow/Yellow-700 : added
// color/STROKE/Feedback/Inverse - Warning	Base	Yellow/Yellow-300 : added
// color/STROKE/Feedback/Info	Base	Blue/Blue-500 : added
// color/STROKE/Feedback/Inverse - Info	Base	Blue/Blue-300 : added
// color/STROKE/Feedback/Active	Base	Neutrals/Neutral-1000 : added
// color/STROKE/Feedback/Inverse - Active	Base	Neutrals/Neutral - 0 : added
// color/STROKE/Feedback/Inverse/Default	Base	Neutrals/Neutral-800
// color/STROKE/Feedback/Inverse/Inverse - Default	Base	Neutrals/Neutal-50
// color/STROKE/Feedback/Inverse/Error	Base	Red/Red-500
// color/STROKE/Feedback/Inverse/Inverse - Error	Base	Red/Red-300
// color/STROKE/Feedback/Inverse/Success	Base	Green/Green-500
// color/STROKE/Feedback/Inverse/Inverse - Success	Base	Green/Green-300
// color/STROKE/Feedback/Inverse/Warning	Base	Yellow/Yellow-700
// color/STROKE/Feedback/Inverse/Inverse - Warning	Base	Yellow/Yellow-300
// color/STROKE/Feedback/Inverse/Info	Base	Blue/Blue-500
// color/STROKE/Feedback/Inverse/Inverse - Info	Base	Blue/Blue-300
// color/STROKE/Feedback/Inverse/Active	Base	Neutrals/Neutral - 0
// color/STROKE/Feedback/Inverse/Inverse - Active	Base	Neutrals/Neutral-1000
// color/STROKE/Feedback/Inverse/Default Subtle	Base	Neutrals/Neutral-850

// Dark Theme:

// color/STROKE/Feedback/Inverse - Default	Base	Neutrals/Neutal-50 //added
// color/STROKE/Feedback/Error	Base	Red/Red-500 //added
// color/STROKE/Feedback/Inverse - Error	Base	Red/Red-300 //added
// color/STROKE/Feedback/Success	Base	Green/Green-500 //added
// color/STROKE/Feedback/Inverse - Success	Base	Green/Green-300 //added
// color/STROKE/Feedback/Warning	Base	Yellow/Yellow-700 //added
// color/STROKE/Feedback/Inverse - Warning	Base	Yellow/Yellow-300 //added
// color/STROKE/Feedback/Info	Base	Blue/Blue-500 //added
// color/STROKE/Feedback/Inverse - Info	Base	Blue/Blue-300 //added
// color/STROKE/Feedback/Active	Base	Neutrals/Neutral - 0 //added
// color/STROKE/Feedback/Inverse - Active	Base	Neutrals/Neutral-1000 //added
// color/STROKE/Feedback/Inverse/Default	Base	Neutrals/Neutal-200 //added
// color/STROKE/Feedback/Inverse/Inverse - Default	Base	Neutrals/Neutral-800
// color/STROKE/Feedback/Inverse/Error	Base	Red/Red-500
// color/STROKE/Feedback/Inverse/Inverse - Error	Base	Red/Red-300
// color/STROKE/Feedback/Inverse/Success	Base	Green/Green-600
// color/STROKE/Feedback/Inverse/Inverse - Success	Base	Green/Green-300
// color/STROKE/Feedback/Inverse/Warning	Base	Yellow/Yellow-700
// color/STROKE/Feedback/Inverse/Inverse - Warning	Base	Yellow/Yellow-300
// color/STROKE/Feedback/Inverse/Info	Base	Blue/Blue-500
// color/STROKE/Feedback/Inverse/Inverse - Info	Base	Blue/Blue-300
// color/STROKE/Feedback/Inverse/Active	Base	Neutrals/Neutral-1000
// color/STROKE/Feedback/Inverse/Inverse - Active	Base	Neutrals/Neutral - 0
// color/STROKE/Feedback/Inverse/Default Subtle	Base	Neutrals/Neutral-100

// Both - accent

// color/BG/Accent/Blue	Base	Blue/Blue-600	Blue/Blue-600 // added
// color/BG/Accent/blue/accent - 1	Base	Blue/Blue-600	Blue/Blue-500 // added
// color/BG/Accent/neutral/neutral 1	Base	Neutrals/Neutral-800	#FFFFFF
// color/BG/Accent/blue/accent - 2	Base	Blue/Blue-200	Blue/Blue-300 // added
// color/BG/Accent/green/Green 1	Base	Green/Green-800	Green/Green-800 // added
// color/BG/Accent/green/Green 2	Base	Green/Green-100	Green/Green-100 // added
